<template>
    <Toast :baseZIndex="9999" />
    <div class="p-grid" v-if="!addStatus && !editStatus">
        <div class="p-col-12">
            <div class="p-d-flex p-ai-center p-jc-between p-flex-column p-flex-lg-row p-mb-1">
                <h5> Web Bot Templates</h5>
                <div>
                    <Button label="Dashboard" class="p-button-sm p-mr-2" @click="goToDashboard" icon="pi pi-th-large" />
                    <Button label="Previous" class="p-button-sm p-mr-1" @click="goToPrevious"
                        icon="pi pi-chevron-left" />
                    <!-- <Button label="Next" icon="pi pi-chevron-right" @click="goToNext" class="p-button-sm"
                        iconPos="right" /> -->
                </div>
            </div>
            <div class="card">
                <div>
                    <span class="subtext p-mt-2 p-text-capitalize">
                        <h6>Client Name : {{ this.localClientName ? this.localClientName : '-' }} </h6>
                    </span>
                </div>
                <div style="position: relative" v-if="!showLoader">
                    <div class="p-py-4 p-px-3">
                        <div class="p-fluid p-formgrid p-grid">
                            <DataTable v-model:first="first" :value="templateSettingList"
                                v-model:selection="selectedProduct" :lazy="true" :paginator="true" :rows="30"
                                :totalRecords="totalRecords" :loading="loading" @page="onPage($event)"
                                class="p-datatable-users" data-key="mbs1" :rowHover="true"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
                                <Toolbar>
                                    <template v-slot:left>
                                        <h5 class="p-m-0">Web Bot Templates </h5>
                                    </template>
                                    <template v-slot:right>
                                       <!-- <Button label="Copy to other client  " icon="pi pi-copy" class="p-mr-2"
                                            @click="onSelectWebBotSettings" style="margin: right 3.5rem;" />-->

                                        <!-- <Button label="Add Web Bot Template" icon="pi pi-plus"
                                            @click="onAddWebBotTemplateSetting" style="margin: right 3.5rem;"
                                            class="p-mr-2" /> -->

                                        <Button v-if="!showFillFilter" label="Filter" icon="pi pi-filter"
                                            @click="filterlisting()" class="p-mr-2"
                                            style="margin: right 3.5rem; width: 160px;"></Button>
                                        <Button v-if="showFillFilter" label="Filter" icon="pi pi-filter-slash"
                                            class="p-button-success p-mr-2" @click="filterlisting()"
                                            style="margin: right 3.5rem; width: 160px;"></Button>
                                    </template>
                                </Toolbar>
                                <template #empty> No data found. </template>
                                <template #loading> Loading data. Please wait... </template>

                                <Column selectionMode="multiple" headerStyle="width: 5%"></Column>
                                <Column header="Sr." headerStyle="width: 4%">
                                    <template #body="{ index }">
                                        <div>{{ genrateSrNo(index) }}</div>
                                    </template>
                                </Column>
                                <!-- <Column field="body" header="Template Group Id" headerStyle="width: 15%">
                                    <template #body="{ data }">
                                        <div class="p-text-capitalize">
                                            <div class="p-text"> {{ data.mbs1 }}</div>
                                        </div>
                                    </template>
                                </Column> -->
                                <Column field="body" header="Template Used For" headerStyle="width: 30%">
                                    <template #body="{ data }">
                                        <div class="p-text-capitalize">
                                            <div class="p-text"> {{ data.mbs2 }}</div>
                                        </div>
                                    </template>
                                </Column>
                                <Column field="body" header="Parent Group Id" headerStyle="width: 15%">
                                    <template #body="{ data }">
                                        <div class="p-text-capitalize">
                                            <div class="p-text"> {{ data.mbs3 }}</div>
                                        </div>
                                    </template>
                                </Column>
                                <Column header="Child Group Id" headerStyle="width: 15%">
                                    <template #body="{ data }">
                                        <div class="p-text-capitalize">
                                            <span v-if="data.mbs5"> {{ data.mbs5 }}</span>
                                            <span v-else> - </span>
                                        </div>
                                    </template>
                                </Column>
                                <Column header="Action" headerStyle="width: 15%">
                                    <template #body="{ data, index }">
                                        <Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2"
                                            @click="onEditWebBotTemplateSettings(data, index)" />
                                        <Button icon="pi pi-eye" class="p-button-rounded "
                                            @click="viewTemplateMessagePopUp(data)" />
                                    </template>
                                </Column>
                            </DataTable>
                        </div>
                    </div>
                </div>
                <div v-if="showLoader" style="height: 390px; position: relative">
                    <div class="custom-modal-spinner-loader">
                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Add/Edit wTemplate Dialog starts here -->
    <AddEditWebBotTemplateSettings :propsData=editableData :propsData3="languageList" :propsData4=templateSettingList
        :propsData5=index @go_to_previous="handleButtonClick" v-if="addStatus || editStatus" />
    <!-- Add/Edit wTemplate Dialog ends here -->
    <!-- manage sequence starts here -->
    <Dialog v-model:visible="viewTemplateMessage" :style="{ width: '800px' }" :modal="true" :header="dialogHeader"
        class="p-fluid">
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid">
                <div v-if="templ_lang_ids.includes(1) && templateBodyL1" class="p-field p-col-12" :class="columnClass">
                    <div class="p-field p-col-12 p-md-12 p-text-center">
                        <h6>English</h6>
                    </div>
                    <Divider type="dashed" />
                    <div class="p-field p-col-12 p-md-12">
                        <label for="templateBodyL1">
                            <span v-html="templateBodyL1"></span>
                        </label>
                        <div for="imageL1">
                            <a class="whatsapp-image-cursor" v-if="imageL1">
                                <img :src="imageL1" style="vertical-align: middle; height:50px"
                                    class="whatsapp-Sent-image p-mt-1" />
                            </a>
                        </div>
                    </div>
                </div>
                <div v-if="templ_lang_ids.includes(5) && templateBodyL2" class="p-field p-col-12" :class="columnClass">
                    <div class="p-field p-col-12 p-md-12 p-text-center">
                        <h6>Hindi</h6>
                    </div>
                    <Divider type="dashed" />
                    <div class="p-field p-col-12 p-md-12">
                        <label for="templateBodyL2">
                            <span v-html="templateBodyL2"></span>
                        </label>
                    </div>
                    <div for="imageL2">
                        <a class="whatsapp-image-cursor" v-if="imageL2">
                            <img :src="imageL2" style="vertical-align: middle; height:50px"
                                class="whatsapp-Sent-image p-mt-1" />
                        </a>
                    </div>
                </div>
                <div v-if="regional_lang_flag && templateBodyL3" class="p-field p-col-12" :class="columnClass">
                    <div class="p-field p-col-12 p-md-12 p-text-center">
                        <h6>{{ getRegionalLanguage() }}</h6>
                    </div>
                    <Divider type="dashed" />
                    <div class="p-field p-col-12 p-md-12">
                        <label for="templateBodyL3">
                            <span v-html="templateBodyL3"></span>
                        </label>
                    </div>
                    <div for="imageL3">
                        <a class="whatsapp-image-cursor" v-if="imageL3">
                            <img :src="imageL3" style="vertical-align: middle; height:50px"
                                class="whatsapp-Sent-image p-mt-1" />
                        </a>
                    </div>
                </div>
            </div>
        </div>

    </Dialog>
    <!-- <Dialog v-model:visible="viewTemplateMessage" :style="{ width: '800px' }" :modal="true" :header="dialogHeader"
        class="p-fluid">
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-12" v-if="templ_lang_ids.includes(1) && templateBodyL1">
                    <div class="p-field p-col-12 p-md-12 p-text-center">
                        <h6>English</h6>
                    </div>
                    <Divider type="dashed" />
                    <div class="p-field p-col-12 p-md-12">
                        <label for="templateBodyL1">
                            {{ this.templateBodyL1 }}
                        </label>
                    </div>
                </div>
                <div class="p-field p-col-12 p-md-12" v-if="templ_lang_ids.includes(5) && templateBodyL2">
                    <div class="p-field p-col-12 p-md-12 p-text-center">
                        <h6>Hindi</h6>
                    </div>
                    <Divider type="dashed" />
                    <div class="p-field p-col-12 p-md-12">
                        <label for="templateBodyL2">
                            {{ this.templateBodyL2 }}
                        </label>
                    </div>
                </div>
                <div class="p-field p-col-12 p-md-12" v-if="regional_lang_flag && templateBodyL3">
                    <div class="p-field p-col-12 p-md-12 p-text-center">
                        <h6>{{ getRegionalLanguage() }}</h6>
                    </div>
                    <Divider type="dashed" />
                    <div class="p-field p-col-12 p-md-12">
                        <label for="templateBodyL3">
                            {{ this.templateBodyL3 }}
                        </label>
                    </div>
                </div>
            </div>
        </div>

    </Dialog> -->
    <!-- manage sequence ends here -->
    <!-- Copy Settings dialog start here -->
    <!-- <Dialog v-model:visible="isCopyWhatsappSetting" :style="{ width: '600px' }" header="Copy Web Bot Settings"
        :modal="true" class="p-fluid">
        <div class="p-field p-col-12 p-md-9">
            <label for="client_name">
                Select Client
            </label>
            <Dropdown v-model="client_name" :options="clientList" optionLabel="label" appendTo="body"
                class="p-text-capitalize" placeholder="Select client" />
        </div>
        <template #footer>
            <Button v-if="!copysettingloader" label="Submit" icon="pi pi-check" class="p-button-text"
                :disabled="client_name == null || client_name == ''" @click="onCopySettingSubmit" style="width: 90px" />
            <Button v-show="copysettingloader" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                style="width: 90px"></Button>
        </template>
    </Dialog> -->
    <!-- Copy Settings dialog end here -->
    <!----Start of filter--------------------------------->
    <Dialog v-model:visible="filterDialog" :style="{ width: '600px' }" header="Filter" :modal="true" class="p-fluid">
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-12">
                    <label for="next_template_name">
                        Template For
                    </label>
                    <Dropdown id="next_template_name" showClear filter v-model="filter_template_for"
                        :options="templateEventList" optionLabel="label" placeholder="Select ..." appendTo="body">
                    </Dropdown>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="Reset" icon="pi pi-refresh" class="p-button-text" @click="reSetFilteredData()" />
            <Button label="Search" icon="pi pi-search" class="p-mr-2" autofocus
                @click="getFilteredData(filter_template_for)" />

            <!-- @click="getFilteredData(this.chatbotId, filter_template_for)" /> -->
        </template>
    </Dialog>
    <!----End of filter--------------------------------->
</template>

<script>
import ApiService from '../../service/ApiService';
import { useRoute } from 'vue-router';
import router from '@/router';
import useValidate from '@vuelidate/core';
import AddEditWebBotTemplateSettings from './AddEditWebBotTemplateSettings.vue';
import { required, helpers, numeric, maxLength } from '@vuelidate/validators';

export default {
    components: {
        AddEditWebBotTemplateSettings
    },
    data() {
        return {
            templateSettingList: [],
            templateEventList: [],
            isDialogOpen: false,
            expandedRows: [],
            loading: false,
            page_no: 0,
            totalRecords: 0,
            showLoader: false,
            chatbot: '',
            languageList: [],
            shorttemplateNameList: [],
            chatbotList: [],
            row_id: 0,
            // foundUniqueIdErrorMsg: '',
            isCopyWhatsappSetting: false,
            selectedProduct: null,
            copysettingloader: false,
            clientList: [],
            selectedWebBotSettings: [],
            client_name: '',
            localClientName: '',
            addStatus: false,
            editStatus: false,
            editableData: null,
            filter_template_for: '',
            // filter_chatbotId: '',
            filter_language: '',
            filterDialog: false,
            showFillFilter: false,
            index: 0,
            viewTemplateMessage: false,

            v$: useValidate(),
            dialogHeader: '',
            TemplateDescription: '',
            templateuniqueid: '',
            submitted: false,
            templ_lang_ids: '1,5,2',
            templ_lang_vals: 'English,Hindi,Marathi',

            currentTemplData: '',
            templateBodyL1: '',
            templateBodyL2: '',
            templateBodyL3: '',
            regionLangaugeId: '',
            regional_lang_flag: false,
            imageL1: '',
            imageL2: '',
            imageL3: '',

        };
    },
    validations() {
        return {
            templateuniqueid: { required: helpers.withMessage('Please enter template unique Id', required), numeric: helpers.withMessage('Only numbers are allowed', numeric), maxLength: maxLength(3) },
        };
    },
    ApiService: null,
    interval: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        const route = useRoute();
        this.routeParam = route.params.clientSubId;
        // this.chatbotId = route.params.chatbotId;
        this.localClientName = localStorage.localClientName;
        this.loading = true;
        // this.getWebBotTemplateSettings({ chatbotId: this.chatbotId, language: this.filter_language, templateFor: this.filter_template_for ? this.filter_template_for.label : '', clientId: this.routeParam, page_no: this.page_no });
        this.getWebBotTemplateSettings({ language: this.filter_language, templateFor: this.filter_template_for ? this.filter_template_for.label : '', clientId: this.routeParam, page_no: this.page_no });

        this.getTemplateDescriptions();
        // this.getchatbotList();
        this.getLanguages();
    },
    computed: {
        columnClass() {
            const count = this.nonEmptyTemplateCount;
            if (count === 3) {
                return 'p-md-4';
            } else if (count === 2) {
                return 'p-md-6';
            } else {
                return 'p-md-12';
            }
        },
        nonEmptyTemplateCount() {
            let count = 0;
            if (this.templateBodyL1) count++;
            if (this.templateBodyL2) count++;
            if (this.templateBodyL3) count++;
            return count;
        }
    },
    methods: {
        handleButtonClick() {
            this.addStatus = false;
            this.editStatus = false;
            this.editableData = null;
            this.getWebBotTemplateSettings({ language: this.filter_language, templateFor: this.filter_template_for ? this.filter_template_for.label : '', clientId: this.routeParam, page_no: this.page_no })

        },

        getRegionalLanguage() {
            const array = this.templ_lang_ids.split(',');
            for (let i = 0; i < array.length; i++) {
                if (array[i] != 1 && array[i] != 5) {
                    let vals = this.languageList.filter(function (items) {
                        return items.value == array[i];
                    });
                    if (vals.length > 0) {
                        this.regional_lang_flag = true;
                        this.regionLangaugeId = vals[0]['value'];
                        return vals[0]['label'];
                    }
                }
            }
        },
        viewTemplateMessagePopUp(e) {
            this.getRegionalLanguage();
            // this.foundUniqueIdErrorMsg = '';
            this.imageL1 = '';
            this.imageL2 = '';
            this.imageL3 = '';
            this.templateBodyL1 = '';
            this.templateBodyL2 = '';
            this.templateBodyL3 = '';
            this.row_id = e.mbs1;
            this.ApiService.getCurrentWebBotTemplateBody({ rowId: this.row_id, clientId: this.routeParam }).then((data) => {
                if (data.status == 200) {
                    this.currentTemplData = data.data;
                    if (this.currentTemplData && this.currentTemplData.length > 0) {
                        this.currentTemplData.forEach((item, index) => {
                            console.log(index);
                            if (this.templ_lang_ids.includes(item.tg6)) {
                                if (item.tg6 == 1) {
                                    this.templateBodyL1 = item.tg4;
                                    if ((item.tg15 == 1) && (item.tg23 == 1))
                                        this.imageL1 = item.tg16;
                                    // console.log("templateBodyL1");
                                }
                                if (item.tg6 == 5) {
                                    this.templateBodyL2 = item.tg4;
                                    if ((item.tg15 == 1) && (item.tg23 == 1))
                                        this.imageL2 = item.tg16;
                                    // console.log("templateBodyL2");
                                }
                                if (item.tg6 == this.regionLangaugeId) {
                                    this.templateBodyL3 = item.tg4;
                                    // console.log("templateBodyL3");
                                    if ((item.tg15 == 1) && (item.tg23 == 1))
                                        this.imageL3 = item.tg16;
                                }
                            }
                        });
                        this.dialogHeader = "View Template Message";
                        this.viewTemplateMessage = true;
                    } else {
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: "This template is empty", life: 3000 });

                    }
                    this.loading = false;
                } else {
                    this.loading = false;
                }
                this.loading = false;
            });
        },
        // viewTemplateMessagePopUp(e) {
        //     this.getRegionalLanguage();
        //     // this.foundUniqueIdErrorMsg = '';
        //     this.dialogHeader = "View Template Message";
        //     this.viewTemplateMessage = true;
        //     this.row_id = e.mbs1;
        //     this.ApiService.getCurrentWebBotTemplateBody({ rowId: this.row_id, clientId: this.routeParam }).then((data) => {
        //         if (data.status == 200) {
        //             this.currentTemplData = data.data;
        //             this.currentTemplData.forEach((item, index) => {
        //                 console.log(index);
        //                 if (this.templ_lang_ids.includes(item.tg6)) {
        //                     if (item.tg6 == 1) {
        //                         this.templateBodyL1 = item.tg4;
        //                         // console.log("templateBodyL1");
        //                     }
        //                     if (item.tg6 == 5) {
        //                         this.templateBodyL2 = item.tg4;
        //                         // console.log("templateBodyL2");
        //                     }
        //                     if (item.tg6 == this.regionLangaugeId) {
        //                         this.templateBodyL3 = item.tg4;
        //                         // console.log("templateBodyL3");
        //                     }
        //                 }
        //             });
        //             this.loading = false;
        //         } else {
        //             this.loading = false;
        //         }
        //         this.loading = false;
        //     });
        // },
        // isAlreadyPresentTemplateDescriptionId(event) {
        //     this.ApiService.isAlreadyPresentTemplateDescriptionId({ uniqueId: event.target.value, rowId: this.row_id }).then((data) => {
        //         if (data.status == 200) {
        //             this.foundUniqueIdErrorMsg = '';
        //             this.foundUniqueId = data.data;
        //             if (data.count > 0)
        //                 this.foundUniqueIdErrorMsg = "This id is found already please enter different Id";
        //             this.loading = false;
        //         } else {
        //             this.foundUniqueIdErrorMsg = '';
        //             this.loading = false;
        //         }
        //         this.loading = false;
        //     });
        // },

        getLanguages() {
            this.ApiService.getLanguages().then((data) => {
                if (data.status == 200) {
                    this.languageList = data.data;
                } else {
                    this.languageList = '';
                }
            });
        },

        searchLanguage(value) {
            let vals = this.languageList.filter(function (item) {
                return item.value == value;
            });
            if (vals.length > 0) {
                return vals[0]['label']
            }
        },

        getTemplateDescriptions() {
            this.loading = true;
            this.ApiService.getTemplateDescriptions({ clientId: this.routeParam }).then((data) => {
                if (data.status == 200) {
                    this.templateEventList = data.data;
                } else {
                    this.templateEventList = '';
                }
                this.loading = false;
            });
        },

        onSelectWebBotSettings() {
            this.ApiService.getClientListOnlyExceptGivenId({ clientId: this.routeParam }).then((data) => {
                if (data.status == 200) {
                    this.clientList = data.data;
                } else {
                    this.clientList = null;
                }
            });
            this.isCopyWhatsappSetting = true;
        },

        // getchatbotList() {
        //     this.ApiService.getchatbotList({ clientId: this.routeParam }).then((data) => {
        //         if (data.status == 200) {
        //             this.chatbotList = data.data;
        //         } else {
        //             this.chatbotList = null;
        //         }
        //     });
        // },

        // onCopySettingSubmit() {
        //     this.copysettingloader = true;
        //     var formData = new FormData();
        //     if (this.selectedProduct) {
        //         let vals = this.selectedProduct.map(function (item) {
        //             return item;
        //         });
        //         this.selectedWebBotSettings = vals;
        //         formData.append("selectedWebBotSettings", JSON.stringify(this.selectedWebBotSettings));
        //     }
        //     formData.append("clientId", this.routeParam);
        //     formData.append("CopyIntoClientId", this.client_name.value);
        //     // if (this.chatbot)
        //     //     formData.append("chatbotId", this.chatbot.value);
        //     this.ApiService.copyWebBotSettingToOtherClient(formData).then((items) => {
        //         if (items.success == true) {
        //             var successMsg = items.message;
        //             this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
        //             this.getWebBotTemplateSettings({ clientId: this.routeParam, page_no: this.page_no });
        //             this.copysettingloader = false;
        //             this.isCopyWhatsappSetting = false;
        //             this.client_name = "";
        //             this.chatbot = "";
        //             this.selectedWebBotSettings = [];
        //             this.selectedProduct = null;
        //         } else {
        //             var errorMsg = items.message;
        //             this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
        //         }
        //     });
        // },

        // getTemplateDescriptions() {
        //     this.loading = true;
        //     this.ApiService.getTemplateDescriptions({ clientId: this.routeParam }).then((data) => {
        //         if (data.status == 200) {
        //             this.shorttemplateNameList = data.data;
        //         } else {
        //             this.shorttemplateNameList = '';
        //         }
        //         this.loading = false;
        //     });
        // },

        onAddWebBotTemplateSetting() {
            this.addStatus = true;
            this.editStatus = false;
        },

        onEditWebBotTemplateSettings(e, index) {
            console.log(index);
            this.addStatus = false;
            this.editStatus = true;
            this.editableData = e;
            this.index = index;
        },

        async getWebBotTemplateSettings(params) {
            try {
                const result = await this.ApiService.getAllTemplateDescriptions({ ...params })

                this.loading = false;
                if (!result.success) {
                    return this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong.', life: 3000 });
                }
                this.templateSettingList = result.data;
                this.totalRecords = result.count;
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong.', life: 3000 });

            }
        },

        goToDashboard() {
            router.push({
                name: "web-bot-template-settings-dashboard",
                params: { clientSubId: this.routeParam, clientnamevalue: this.localClientName },
            });
        },

        filterlisting() {
            this.filterDialog = true;
        },

        getFilteredData(filter_template_for) {
            this.loading = true;
            // this.filter_chatbotId = filter_chatbotId;
            // let chatbotId = null;
            let template_for = null;
            // if (filter_chatbotId) {
            //     chatbotId = filter_chatbotId.value
            // }
            this.filter_template_for = filter_template_for;
            if (filter_template_for) {
                template_for = filter_template_for.value;
            }
            // this.getWebBotTemplateSettings({
            //     chatbotId: chatbotId, templateFor: template_for, clientId: this.routeParam
            // });
            this.getWebBotTemplateSettings({
                templateFor: template_for, clientId: this.routeParam
            });
            this.showFillFilter = true;
            this.filterDialog = false;
        },

        reSetFilteredData() {
            this.filter_template_for = "";
            // this.filter_chatbotId = '';
            this.filter_language = '';
            this.getWebBotTemplateSettings({ clientId: this.routeParam });
            this.showFillFilter = false;
            this.filterDialog = false;
        },

        goToPrevious() {
            router.push({
                name: "listTemplateDescription",
                params: { clientSubId: this.routeParam, clientnamevalue: this.localClientName },
            });
        },

        onPage(event) {
            this.loading = true;
            this.page_no = event.page;
            // this.getWebBotTemplateSettings({ chatbotId: this.filter_chatbotId, templateFor: this.filter_template_for ? this.filter_template_for.label : '', clientId: this.routeParam, page_no: this.page_no });
            this.getWebBotTemplateSettings({ templateFor: this.filter_template_for ? this.filter_template_for.label : '', clientId: this.routeParam, page_no: this.page_no });

        },
    }

};
</script>

<style scoped></style>
